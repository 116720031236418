// Utils
export * from './utils/createSvgIcon';
export * from './utils/types';
export * from './utils/iconClassNames';

// Icon components
export { AcceptIcon } from './components/AcceptIcon';
export { AddIcon } from './components/AddIcon';
export { ApprovalsAppbarIcon } from './components/ApprovalsAppbarIcon';
export { AppsIcon } from './components/AppsIcon';
export { ArchiveIcon } from './components/ArchiveIcon';
export { ArrowDownIcon } from './components/ArrowDownIcon';
export { ArrowLeftIcon } from './components/ArrowLeftIcon';
export { ArrowRightIcon } from './components/ArrowRightIcon';
export { ArrowSortIcon } from './components/ArrowSortIcon';
export { ArrowUpIcon } from './components/ArrowUpIcon';
export { AudienceIcon } from './components/AudienceIcon';
export { AudioLoadingIcon } from './components/AudioLoadingIcon';
export { AudioOffIcon } from './components/AudioOffIcon';
export { AttendeeIcon } from './components/AttendeeIcon';
export { BanIcon } from './components/BanIcon';
export { BellIcon } from './components/BellIcon';
export { BellSlashIcon } from './components/BellSlashIcon';
export { BellSnoozeIcon } from './components/BellSnoozeIcon';
export { BluetoothIcon } from './components/BluetoothIcon';
export { BoldIcon } from './components/BoldIcon';
export { BookmarkIcon } from './components/BookmarkIcon';
export { BreakoutRoomIcon } from './components/BreakoutRoomIcon';
export { BroadcastIcon } from './components/BroadcastIcon';
export { BroadcastViewFullscreenIcon } from './components/BroadcastViewFullscreenIcon';
export { BroadcastViewLeftIcon } from './components/BroadcastViewLeftIcon';
export { BulletsIcon } from './components/BulletsIcon';
export { CalendarIcon } from './components/CalendarIcon';
export { CalendarAgendaIcon } from './components/CalendarAgendaIcon';
export { CallBlockedIcon } from './components/CallBlockedIcon';
export { CallControlCloseTrayIcon } from './components/CallControlCloseTrayIcon';
export { CallControlReleaseIcon } from './components/CallControlReleaseIcon';
export { CallControlRequestIcon } from './components/CallControlRequestIcon';
export { CallControlPresentNewIcon } from './components/CallControlPresentNewIcon';
export { CallControlShareIcon } from './components/CallControlShareIcon';
export { CallControlStopPresentingNewIcon } from './components/CallControlStopPresentingNewIcon';
export { CallDialpadIcon } from './components/CallDialpadIcon';
export { CallEndIcon } from './components/CallEndIcon';
export { CallIcon } from './components/CallIcon';
export { CallPstnIcon } from './components/CallPstnIcon';
export { CallRecordingIcon } from './components/CallRecordingIcon';
export { CallVideoIcon } from './components/CallVideoIcon';
export { CallVideoOffIcon } from './components/CallVideoOffIcon';
export { CameraIcon } from './components/CameraIcon';
export { CanvasAddPageIcon } from './components/CanvasAddPageIcon';
export { CallMissedLineIcon } from './components/CallMissedLineIcon';
export { CallParkingIcon } from './components/CallParkingIcon';
export { CastingIcon } from './components/CastingIcon';
export { ChannelShareIcon } from './components/ChannelShareIcon';
export { ChatIcon } from './components/ChatIcon';
export { ChatOffIcon } from './components/ChatOffIcon';
export { CheckmarkCircleIcon } from './components/CheckmarkCircleIcon';
export { ChevronDownIcon } from './components/ChevronDownIcon';
export { ChevronDownMediumIcon } from './components/ChevronDownMediumIcon';
export { ChevronEndIcon } from './components/ChevronEndIcon';
export { ChevronEndMediumIcon } from './components/ChevronEndMediumIcon';
export { ChevronStartIcon } from './components/ChevronStartIcon';
export { CircleIcon } from './components/CircleIcon';
export { ClipboardCopiedToIcon } from './components/ClipboardCopiedToIcon';
export { ClosedCaptionsIcon } from './components/ClosedCaptionsIcon';
export { CloseIcon } from './components/CloseIcon';
export { CodeSnippetIcon } from './components/CodeSnippetIcon';
export { CollapseIcon } from './components/CollapseIcon';
export { CompanionIcon } from './components/CompanionIcon';
export { ComposeIcon } from './components/ComposeIcon';
export { ComputerAudioIcon } from './components/ComputerAudioIcon';
export { ComputerAudioOffIcon } from './components/ComputerAudioOffIcon';
export { ConferenceRoomDeviceIcon } from './components/ConferenceRoomDeviceIcon';
export { ContactCardIcon } from './components/ContactCardIcon';
export { ContactGroupCallIcon } from './components/ContactGroupCallIcon';
export { ContactGroupIcon } from './components/ContactGroupIcon';
export { ContentIcon } from './components/ContentIcon';
export { CustomerHubIcon } from './components/CustomerHubIcon';
export { DoorArrowLeftIcon } from './components/DoorArrowLeftIcon';
export { DownloadIcon } from './components/DownloadIcon';
export { EditIcon } from './components/EditIcon';
export { EmailIcon } from './components/EmailIcon';
export { EmailWithDotIcon } from './components/EmailWithDotIcon';
export { EmojiIcon } from './components/EmojiIcon';
export { EmojiSadIcon } from './components/EmojiSadIcon';
export { EmojiSurprisedIcon } from './components/EmojiSurprisedIcon';
export { ErrorIcon } from './components/ErrorIcon';
export { ExcelColorIcon } from './components/ExcelColorIcon';
export { ExcelIcon } from './components/ExcelIcon';
export { ExclamationCircleIcon } from './components/ExclamationCircleIcon';
export { ExclamationTriangleIcon } from './components/ExclamationTriangleIcon';
export { ExpandIcon } from './components/ExpandIcon';
export { EyeIcon } from './components/EyeIcon';
export { EyeFriendlierIcon } from './components/EyeFriendlierIcon';
export { EyeSlashIcon } from './components/EyeSlashIcon';
export { FilesAftereffectsIcon } from './components/FilesAftereffectsIcon';
export { FilesCodeIcon } from './components/FilesCodeIcon';
export { FilesEmptyIcon } from './components/FilesEmptyIcon';
export { FilesErrorIcon } from './components/FilesErrorIcon';
export { FilesFlashIcon } from './components/FilesFlashIcon';
export { FilesGenericColoredIcon } from './components/FilesGenericColoredIcon';
export { FilesGifIcon } from './components/FilesGifIcon';
export { FilesHtmlColoredIcon } from './components/FilesHtmlColoredIcon';
export { FilesIllustratorIcon } from './components/FilesIllustratorIcon';
export { FilesImageIcon } from './components/FilesImageIcon';
export { FilesIndesignIcon } from './components/FilesIndesignIcon';
export { FilesPdfColoredIcon } from './components/FilesPdfColoredIcon';
export { FilesPdfIcon } from './components/FilesPdfIcon';
export { FilesPhotoshopIcon } from './components/FilesPhotoshopIcon';
export { FilesPictureColoredIcon } from './components/FilesPictureColoredIcon';
export { FilesSketchIcon } from './components/FilesSketchIcon';
export { FilesSoundIcon } from './components/FilesSoundIcon';
export { FilesTextColoredIcon } from './components/FilesTextColoredIcon';
export { FilesTxtIcon } from './components/FilesTxtIcon';
export { FilesUploadIcon } from './components/FilesUploadIcon';
export { FilesVideoIcon } from './components/FilesVideoIcon';
export { FilesZipIcon } from './components/FilesZipIcon';
export { FilterIcon } from './components/FilterIcon';
export { FlagIcon } from './components/FlagIcon';
export { FluidIcon } from './components/FluidIcon';
export { FluidFileIcon } from './components/FluidFileIcon';
export { FontColorIcon } from './components/FontColorIcon';
export { FontSizeIcon } from './components/FontSizeIcon';
export { FormatIcon } from './components/FormatIcon';
export { GalleryIcon } from './components/GalleryIcon';
export { GalleryNewIcon } from './components/GalleryNewIcon';
export { GalleryNewLargeIcon } from './components/GalleryNewLargeIcon';
export { GeofenceArrivesIcon } from './components/GeofenceArrivesIcon';
export { GeofenceLeavesIcon } from './components/GeofenceLeavesIcon';
export { GeofenceArrivesOrLeavesIcon } from './components/GeofenceArrivesOrLeavesIcon';
export { GiphyIcon } from './components/GiphyIcon';
export { GlassesIcon } from './components/GlassesIcon';
export { GridIcon } from './components/GridIcon';
export { GroupVideoCallGridIcon } from './components/GroupVideoCallGridIcon';
export { HandIcon } from './components/HandIcon';
export { HeadsetIcon } from './components/HeadsetIcon';
export { HighlightIcon } from './components/HighlightIcon';
export { HorizontalRuleIcon } from './components/HorizontalRuleIcon';
export { ImageAltTextIcon } from './components/ImageAltTextIcon';
export { ImageLibraryIcon } from './components/ImageLibraryIcon';
export { ImageUnavailableIcon } from './components/ImageUnavailableIcon';
export { IndentIcon } from './components/IndentIcon';
export { InfoIcon } from './components/InfoIcon';
export { ItalicIcon } from './components/ItalicIcon';
export { LeaveIcon } from './components/LeaveIcon';
export { LightningIcon } from './components/LightningIcon';
export { LikeIcon } from './components/LikeIcon';
export { LinkIcon } from './components/LinkIcon';
export { LiveIcon } from './components/LiveIcon';
export { LiveOffIcon } from './components/LiveOffIcon';
export { LocationIcon } from './components/LocationIcon';
export { LockIcon } from './components/LockIcon';
export { MarkAsUnreadIcon } from './components/MarkAsUnreadIcon';
export { MeetingNewIcon } from './components/MeetingNewIcon';
export { MeetingTimeIcon } from './components/MeetingTimeIcon';
export { MegaphoneIcon } from './components/MegaphoneIcon';
export { MentionIcon } from './components/MentionIcon';
export { MenuIcon } from './components/MenuIcon';
export { MergeCallsIcon } from './components/MergeCallsIcon';
export { MessageSeenIcon } from './components/MessageSeenIcon';
export { MicIcon } from './components/MicIcon';
export { MicOffIcon } from './components/MicOffIcon';
export { MicrophoneDisabledIcon } from './components/MicrophoneDisabledIcon';
export { MicrosoftStreamIcon } from './components/MicrosoftStreamIcon';
export { MoreIcon } from './components/MoreIcon';
export { NoPresenterIcon } from './components/NoPresenterIcon';
export { NotesIcon } from './components/NotesIcon';
export { NotificationsMutedIcon } from './components/NotificationsMutedIcon';
export { NumberListIcon } from './components/NumberListIcon';
export { OcrOffIcon } from './components/OcrOffIcon';
export { OcrOnIcon } from './components/OcrOnIcon';
export { OneNoteColorIcon } from './components/OneNoteColorIcon';
export { OneNoteIcon } from './components/OneNoteIcon';
export { OneNoteMonoIcon } from './components/OneNoteMonoIcon';
export { OneDriveIcon } from './components/OneDriveIcon';
export { OptionsIcon } from './components/OptionsIcon';
export { OutdentIcon } from './components/OutdentIcon';
export { OpenOutsideIcon } from './components/OpenOutsideIcon';
export { PanoramaIcon } from './components/PanoramaIcon';
export { PanoramaOffIcon } from './components/PanoramaOffIcon';
export { PaperclipIcon } from './components/PaperclipIcon';
export { ParticipantAddIcon } from './components/ParticipantAddIcon';
export { ParticipantRemoveIcon } from './components/ParticipantRemoveIcon';
export { PauseIcon } from './components/PauseIcon';
export { PauseThickIcon } from './components/PauseThickIcon';
export { PhoneArrowIcon } from './components/PhoneArrowIcon';
export { PhoneClockIcon } from './components/PhoneClockIcon';
export { PlayIcon } from './components/PlayIcon';
export { PlugsIcon } from './components/PlugsIcon';
export { PollIcon } from './components/PollIcon';
export { PopupIcon } from './components/PopupIcon';
export { PowerPointColorIcon } from './components/PowerPointColorIcon';
export { PowerPointIcon } from './components/PowerPointIcon';
export { PresenceAvailableIcon } from './components/PresenceAvailableIcon';
export { PresenceStrokeIcon } from './components/PresenceStrokeIcon';
export { PresenterIcon } from './components/PresenterIcon';
export { QnaIcon } from './components/QnaIcon';
export { QuestionCircleIcon } from './components/QuestionCircleIcon';
export { QuoteIcon } from './components/QuoteIcon';
export { RadioButtonIcon } from './components/RadioButtonIcon';
export { ReadAloudIcon } from './components/ReadAloudIcon';
export { RaiseHandColoredIcon } from './components/RaiseHandColoredIcon';
export { RaiseHandDisabledIcon } from './components/RaiseHandDisabledIcon';
export { RaiseHandIcon } from './components/RaiseHandIcon';
export { ReactionsIcon } from './components/ReactionsIcon';
export { RedbangIcon } from './components/RedbangIcon';
export { RedoIcon } from './components/RedoIcon';
export { RemoveFormatIcon } from './components/RemoveFormatIcon';
export { ReplyIcon } from './components/ReplyIcon';
export { RetryIcon } from './components/RetryIcon';
export { RobotIcon } from './components/RobotIcon';
export { SaveIcon } from './components/SaveIcon';
export { ScreencastIcon } from './components/ScreencastIcon';
export { ScreenshareIcon } from './components/ScreenshareIcon';
export { SearchIcon } from './components/SearchIcon';
export { SendIcon } from './components/SendIcon';
export { SettingsIcon } from './components/SettingsIcon';
export { SettingsAudioIcon } from './components/SettingsAudioIcon';
export { ShareAltIcon } from './components/ShareAltIcon';
export { ShareGenericIcon } from './components/ShareGenericIcon';
export { ShareLocationIcon } from './components/ShareLocationIcon';
export { ShareToIcon } from './components/ShareToIcon';
export { ShieldKeyholeIcon } from './components/ShieldKeyholeIcon';
export { Shift24hIcon } from './components/Shift24hIcon';
export { ShiftActivityIcon } from './components/ShiftActivityIcon';
export { SkypeLogoIcon } from './components/SkypeLogoIcon';
export { SpeakerMuteIcon } from './components/SpeakerMuteIcon';
export { SpeakerPersonIcon } from './components/SpeakerPersonIcon';
export { SpotlightIcon } from './components/SpotlightIcon';
export { SpotlightStopIcon } from './components/SpotlightStopIcon';
export { StarIcon } from './components/StarIcon';
export { StickerIcon } from './components/StickerIcon';
export { StrikeIcon } from './components/StrikeIcon';
export { SurveyIcon } from './components/SurveyIcon';
export { SwitchCameraIcon } from './components/SwitchCameraIcon';
export { SyncIcon } from './components/SyncIcon';
export { TableAddIcon } from './components/TableAddIcon';
export { TableDeleteIcon } from './components/TableDeleteIcon';
export { TableIcon } from './components/TableIcon';
export { TabsIcon } from './components/TabsIcon';
export { TagIcon } from './components/TagIcon';
export { TeamCreateIcon } from './components/TeamCreateIcon';
export { TeamsIcon } from './components/TeamsIcon';
export { TeamsMonochromeIcon } from './components/TeamsMonochromeIcon';
export { TenantWorkIcon } from './components/TenantWorkIcon';
export { TenantPersonalIcon } from './components/TenantPersonalIcon';
export { ThumbtackIcon } from './components/ThumbtackIcon';
export { ThumbtackSlashIcon } from './components/ThumbtackSlashIcon';
export { TiltPanZoomIcon } from './components/TiltPanZoomIcon';
export { ToDoListIcon } from './components/ToDoListIcon';
export { TranscriptIcon } from './components/TranscriptIcon';
export { TranslationIcon } from './components/TranslationIcon';
export { TrashCanIcon } from './components/TrashCanIcon';
export { TriangleEndIcon } from './components/TriangleEndIcon';
export { TriangleDownIcon } from './components/TriangleDownIcon';
export { TriangleUpIcon } from './components/TriangleUpIcon';
export { TvIcon } from './components/TvIcon';
export { UnderlineIcon } from './components/UnderlineIcon';
export { UndoIcon } from './components/UndoIcon';
export { UrgentIcon } from './components/UrgentIcon';
export { UserBlurIcon } from './components/UserBlurIcon';
export { UserFriendsIcon } from './components/UserFriendsIcon';
export { UserPhoneIcon } from './components/UserPhoneIcon';
export { VideoCameraEmphasisIcon } from './components/VideoCameraEmphasisIcon';
export { VideoLoadingIcon } from './components/VideoLoadingIcon';
export { VideomailIcon } from './components/VideomailIcon';
export { VideoProhibitedIcon } from './components/VideoProhibitedIcon';
export { VisioColorIcon } from './components/VisioColorIcon';
export { VisioIcon } from './components/VisioIcon';
export { VolumeIcon } from './components/VolumeIcon';
export { VolumeDownIcon } from './components/VolumeDownIcon';
export { VolumeUpIcon } from './components/VolumeUpIcon';
export { WandIcon } from './components/WandIcon';
export { WhiteboardIcon } from './components/WhiteboardIcon';
export { WindowMaximizeIcon } from './components/WindowMaximizeIcon';
export { WindowMinimizeIcon } from './components/WindowMinimizeIcon';
export { WindowRestoreIcon } from './components/WindowRestoreIcon';
export { WordColorIcon } from './components/WordColorIcon';
export { WordIcon } from './components/WordIcon';
export { WorkOrSchoolIcon } from './components/WorkOrSchoolIcon';
export { YammerIcon } from './components/YammerIcon';
export { ZoomInIcon } from './components/ZoomInIcon';
export { ZoomOutIcon } from './components/ZoomOutIcon';
export { ZoomToFitIcon } from './components/ZoomToFitIcon';
